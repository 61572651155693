import { authUrl } from '@/config';
import crypto from '@/utils/crypto'
import request from '../axios';
import {clearBladexDatavToken} from '@/utils/auth'

export const captcha = (data) => {
  return request({
    url: authUrl + '/oauth/captcha',
    method: 'get',
    headers: {
      'data': crypto.encrypt(JSON.stringify(data)),
      'Content-Type': 'application/json',
    },
    data: crypto.encrypt(JSON.stringify(data))
  })
}

export const login = (data) => {
  return request({
    url: authUrl + '/oauth/token',
    method: 'post',
    headers: {
      'data': crypto.encrypt(JSON.stringify(data)),
      'Content-Type': 'application/json',
    },
    data: data
  })
}

export const logout = (data)=> {
  console.log(data)
  clearBladexDatavToken();
}