<template>
    <div class="login">
        <div style="padding-bottom: 120px;">
            <h2>供管系统 DataV</h2>
            <el-form label-position="right" label-width="100px" :model="formLabelAlign" ref="formLabelAlign" :rules="rules" >
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="formLabelAlign.username" placeholder="请输入用户名" class="bg-fff">
                        <i slot="suffix" class="el-input__icon el-icon-error" v-if="formLabelAlign.username" @click="formLabelAlign.username = ''"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="formLabelAlign.password" placeholder="请输入密码" class="bg-fff">
                        <i slot="suffix" class="el-input__icon el-icon-error" v-if="formLabelAlign.password" @click="formLabelAlign.password = ''"></i>
                    </el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="captcha" class="captcha-dom">
                    <el-input v-model="formLabelAlign.captcha" placeholder="请输入验证码" class="bg-fff padding-right-45">
                        <!--<el-image slot="append" style="width: 130px; height: 48px"
                                  @click="refresh"
                                  :src="captcha_src"
                                  :fit="fit"></el-image>-->
                        <i slot="append" class="el-input__icon el-icon-error" v-if="formLabelAlign.captcha" @click="formLabelAlign.captcha = ''"></i>
                        <img slot="suffix" :src="captcha_src" alt="验证码" @click="refresh" class="captcha">
                    </el-input>

                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('formLabelAlign')" style="width: 252px;">登录</el-button>
                    <el-button @click="resetForm('formLabelAlign')">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
    import { captcha, login } from "@/api/user";
    import {setBladexDatavToken} from '@/utils/auth'
    // 引入去除空格工具
    export default {
        name: "login",
        components: {},
        data() {
            return {
                fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
                captcha_src: '',
                formLabelAlign: {
                    captcha_key: '',
                    captcha: '',
                    username: '',
                    password: ''
                },
                rules: {
                    username: [{required: true, message: '请输入用户名', trigger: 'blur'}],
                    password: [{required: true, message: '请输入密码', trigger: 'blur'}],
                    captcha: [{required: true, message: '请输入验证码', trigger: 'blur'}],
                }
            };
        },
        mounted() {
            this.refresh();
        },
        methods: {
            /*handleChange (item, index) {
              this.$router.push({ path: item.path })
            }*/
            /**
             * 刷新验证码
             */
            refresh() {
                captcha().then(res=> {
                    if (res && res.status === 200){
                        let res_data = res.data
                        this.$message.success('图片验证码刷新成功')
                        this.captcha_src = res_data.image;
                        this.formLabelAlign.captcha_key = res_data.key;
                    }
                })

            },
            /**
             * 登录
             * @param formName
             */
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        login({
                            captchaKey: this.formLabelAlign.captcha_key,
                            captcha: this.formLabelAlign.captcha,
                            username: this.formLabelAlign.username,
                            password: this.formLabelAlign.password,
                        }).then(res=> {
                            if (res && res.status === 200) {
                                let res_data = res.data
                                if (res_data.success === 'false') {
                                    return this.$message.error(res_data.msg ? res_data.msg : '未知错误');
                                }
                                this.$message.success('登录成功');
                                setBladexDatavToken(res_data)
                                window.location.href = '/'
                                //this.$router.push({path: '/'})
                            }
                        })
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .login{
        background: #ffffff;
        background: url(../../assets/login_bg.jpeg);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    h2{
        text-align: center;
        padding-bottom: 20px;
        color: #ffffff;
        font-size: 40px;
    }
    .captcha-dom .el-form-item__content{
        display: flex;
        flex-direction: column;
    }
    .captcha{
        height: 40px;
    }
</style>
